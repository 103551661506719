import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {
  AuthenticationService,
  Customer,
  CustomerService,
  DateService as CommonDateService,
  FileService,
  Project,
  ProjectService,
  RestService,
  API_URL_GCP
} from 'commons';
import {AppService} from '../../services/app.service';
import {TimeService} from '../../services/time.service';
import {PaginationService} from '../../services/pagination.service';
import {DateService} from '../../services/date.service';
import {Time} from '../../model/model';

import * as moment from 'moment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit, AfterViewChecked {

  public title: string;
  public times: Time[] = [];

  public filter = {
    year: null,
    month: null,
    customer: null,
    enabled: false
  };

  public personId: string;
  public date: Date;

  public customers: Customer[];
  public projects: Project[];

  public mapCustomerWorkingHours: Map<string, number> = new Map();

  public numberOfWorkingHoursInCurrentDay = 0;
  public numberOfWorkingHoursInCurrentWeek = 0;
  public numberOfWorkingHoursInCurrentMonth = 0;

  constructor(private cdRef: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private appService: AppService,
              private restService: RestService,
              private fileService: FileService,
              private _dateService: DateService,
              private authenticationService: AuthenticationService,
              private timeService: TimeService,
              private customerService: CustomerService,
              private _projectService: ProjectService,
              public paginationService: PaginationService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.filter.year = AppService.isNumeric(params.year)
        ? params.year
        : new Date().getFullYear();
      this.filter.month = AppService.isNumeric(params.month)
        ? params.month
        : new Date().getMonth() + 1;
      this.filter.customer = (params.customer) ? params.customer : null;
      this.filter.enabled = false;

      this.date = new Date(this.filter.year, this.filter.month - 1, 1);
    });

    this.init();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private async init() {
    await this.authenticationService.getToken().then(
      accessToken => {
        this.authenticationService.token = accessToken;
      },
      () => {
        this.authenticationService.token = null;
      }
    );

    await this.authenticationService.getPerson().then(
      person => {
        if (person != null && typeof person.id != 'undefined') {
          this.personId = person.id;
        }
      },
      () => {
        this.personId = null;
      }
    );

    await this.customerService.findAll().then(response => {
      this.customers = response;
    });

    await this._projectService.findAll().then(response => {
      this.projects = response;
    });

    this.getTimes();
  }

  private getCustomerById(id: string): Customer {
    const list = this.customers.filter(customer => customer.id === id);
    return (list.length > 0) ? list[0] : null;
  }

  private getProjectById(id: string): Project {
    const list = this.projects.filter(project => project.id === id);
    return (list.length > 0) ? list[0] : null;
  }

  private getTimes(): void {
    this.timeService.findByPersonAndPeriod(this.personId, this.filter.year, this.filter.month)
      .pipe(
        map(times => {
          this.calculateNumberOfWorkingHours(times);
          return times;
        })
      )
      .subscribe((times) => {
        this.times = (times instanceof Array) ? times : [];
        this.paginationService.init(this.times.length, 10);
      });
  }

  private async calculateNumberOfWorkingHours(tasks: Time[]) {
    this.numberOfWorkingHoursInCurrentDay = 0;
    this.numberOfWorkingHoursInCurrentWeek = 0;
    this.numberOfWorkingHoursInCurrentMonth = 0;
    this.mapCustomerWorkingHours = new Map();

    const isSameWeek = (date: string) => {
      return moment(date).isoWeek() === moment().isoWeek();
    };
    const isSameMonth = (timestamp: string) => {
      return moment(timestamp).format('YY-MMMM') === moment().format('YY-MMMM');
    };

    tasks.forEach((time: Time) => {
      const project = this.getProjectById(time.projectId);
      const customer = this.getCustomerById(project.customerId);

      if (customer != null && customer.company != null) {
        const match = (this.mapCustomerWorkingHours.has(customer.company))
          ? this.mapCustomerWorkingHours.get(customer.company) + time.duration
          : time.duration;
        this.mapCustomerWorkingHours.set(customer.company, match);
      }

      if (this._dateService.isToday(time.dateOfService)) {
        this.numberOfWorkingHoursInCurrentDay += +time.duration;
      }
      if (isSameWeek(time.dateOfService)) {
        this.numberOfWorkingHoursInCurrentWeek += +time.duration;
      }
      if (isSameMonth(time.dateOfService)) {
        this.numberOfWorkingHoursInCurrentMonth += +time.duration;
      }
    });
  }

  public toDateString(time: Time): string {
    return DateService.toDateString(time);
  }

  public getMinutesInHours(min: number): string {
    return (min / 60).toFixed(2);
  }

  public getDropdownYears(): number[] {
    return CommonDateService.getYears();
  }

  public getDropdownMonths(): string[] {
    return CommonDateService.getMonths();
  }

  public applyFilterChanges() {
    let params: any = {
      year: this.filter.year,
      month: this.filter.month
    };
    if (this.filter.customer != null && this.filter.customer != '') {
      params.customer = this.filter.customer;
    }
    this.router.navigate(['times'], {queryParams: params})
      .then(() => {
        this.getTimes();
      });
  }

  public getTableRowClazzName(rowIndex: number, time: Time): string {
    let clazzName = this.paginationService.itemIsEnabled(rowIndex) ? '' : 'hidden';
    if (this._dateService.isToday(time.dateOfService)) {
      clazzName += ' row-today';
    }
    if (this._dateService.isFuture(time.dateOfService)) {
      clazzName += ' row-future';
    }
    return clazzName;
  }

  public export() {
    let url = API_URL_GCP + '/sleektime/export/times'
      + '?year=' + this.filter.year
      + '&month=' + this.filter.month
      + '&personId=' + this.personId;
    if (this.filter.customer != null) {
      url += '&customerId=' + this.filter.customer;
    }

    this.appService.downloadExcelFile(url);
  }

  public getProjectNameById(projectId: string): string {
    const project = this.getProjectById(projectId);
    return project
      ? project.nameShort + ' ' + project.name
      : 'Project not found.';
  }

  public getCustomerNameByProjectId(projectId: string): string {
    const project = this.getProjectById(projectId);
    if (project) {
      const customer = this.getCustomerById(project.customerId);
      return customer ? customer.company : 'Customer not found.';
    }
    return 'Customer not found.';
  }
}
