import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService, NavItem} from 'commons';
import {DataService} from './services/data.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {

  public url: string;
  public nav: NavItem[];
  public isLoggedIn = this._authService.isLoggedIn();

  public token: string;

  public constructor(private _router: Router,
                     private _cd: ChangeDetectorRef,
                     private _authService: AuthenticationService,
                     private _dataService: DataService) {
  }

  ngOnInit() {
    this.token = AppComponent.getTokenValue();
    this._cd.markForCheck();

    this.isLoggedIn.subscribe(value => {
      if (value === true) {
        this.buildNavLinks();
      } else {
        this.nav = [];
      }
    });
  }

  private static getTokenValue() {
    const hash = window.location.hash;
    if (hash.includes('?token=')) {
      return 'Bearer ' + decodeURIComponent(hash.split('?token=')[1]);
    }
    return null;
  }

  private buildNavLinks(): void {
    this.url = window.location.hash;
    const matchPath = (path) => {
      return (this.url == path);
    };
    this.nav = [
      {
        text: 'Overview',
        link: 'times',
        active: matchPath('times') },
      {
        text: 'Search',
        link: 'times/search',
        active: matchPath('times/search') },
      {
        text: 'Stats',
        link: 'times/stats',
        active: matchPath('times/stats') }
    ];
  }

  public navigate(link: string) {
    this._router.navigate([link]).then(() => {
      this.url = window.location.hash.substr(2);
      this.buildNavLinks();
    });
  }
}
