import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TimeComponent} from './component/time/time.component';
import {TimeAddComponent} from './component/time-add/time-add.component';
import {TimeUpdateComponent} from './component/time-update/time-update.component';
import {TimeSearchComponent} from './component/time-search/time-search.component';
import {StatsComponent} from './component/stats/stats.component';

const routes: Routes = [
  {path: '', redirectTo: 'times', pathMatch: 'full'},

  // Time
  {
    path: 'times', children: [
      {path: '', component: TimeComponent},
      {path: 'view/:id', component: TimeUpdateComponent},
      {path: 'search', component: TimeSearchComponent},
      {path: 'stats', component: StatsComponent},
      {path: 'add', component: TimeAddComponent},
      {path: 'update/:id', component: TimeUpdateComponent},
      {path: 'delete/:id', component: TimeUpdateComponent}
    ]
  },
  {path: 'me', component: TimeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
