import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AuthenticationService,
  Customer,
  CustomerService,
  Project,
  ProjectService
} from 'commons';
import {TimeService} from '../../services/time.service';
import {PaginationService} from '../../services/pagination.service';
import {Time} from '../../model/model';

import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-time-search',
  templateUrl: './time-search.component.html',
  styleUrls: ['./time-search.component.scss']
})
export class TimeSearchComponent implements OnInit {

  public title: string;

  public queryParams = {
    key: null,
    project: null,
    start: null,
    end: null
  };

  public numberOfWorkingHours = 0;

  public customers: Promise<Customer[]>;
  public projects: Promise<Project[]>;
  public times: Observable<Time[]>;

  public dropdownCustomerText = 'Please select';
  public dropdownProjectText = 'Please select';

  private customer: Customer = null;
  private project: Project = null;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private customerService: CustomerService,
              private projectService: ProjectService,
              private timeService: TimeService,
              public paginationService: PaginationService) {
  }

  ngOnInit() {
    this.customers = this.customerService.findAll();

    this.authenticationService.getToken().then(
        accessToken => {
          this.authenticationService.token = accessToken;
          this.initialize();
        },
        error => {
          this.authenticationService.token = null;
          this.times = new Observable<Time[]>();
        }
    );
  }

  private initialize() {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams.project = (typeof params.project != 'undefined') ? params.project : null;
      this.queryParams.start = (typeof params.start != 'undefined') ? params.start : null;
      this.queryParams.end = (typeof params.end != 'undefined') ? params.end : null;
      this.queryParams.key = (typeof params.key != 'undefined') ? params.key : null;
    });

    if (this.isFilterSelected() == true) {
      if (this.queryParams.project == null) {
        this.getTimes();
      } else {
        this.getTimesByProject();

        this.projectService.findById(this.queryParams.project)
            .then(project => {
              this.customerService.findById(project.customerId).then(customer => {
                this.onCustomerChanged(customer);
              });

              this.project = project;
              this.dropdownProjectText = project.nameShort + ': ' + project.name;
            });
      }
    }
  }

  private getTimes(): void {
    this.times = this.timeService.findByPerson(this.authenticationService.person.id)
        .pipe(
            map(times => {
              times = this.filter(times);
              this.paginationService.init(times.length, 5);
              this.calculateNumberOfWorkingHours(times);
              return times;
            })
        );
  }

  private getTimesByProject(): void {
    this.times = this.timeService.findByProject(this.queryParams.project)
        .pipe(
            map(times => {
              times = this.filter(times);
              this.paginationService.init(times.length, 5);
              this.calculateNumberOfWorkingHours(times);
              return times;
            })
        );
  }

  private calculateNumberOfWorkingHours(times: Time[]) {
    let duration = 0;
    times.forEach(time => {
      duration += +time.duration;
    });
    this.numberOfWorkingHours = duration;
  }

  private filter(times: Time[]): Time[] {
    const filter: Time[] = [];
    const isKeywordDefined = (this.queryParams.key != null);
    const key = (isKeywordDefined) ? this.queryParams.key.toLowerCase() : null;
    const startDateDefined = (this.queryParams.start != null);
    const endDateDefined = (this.queryParams.end != null);

    // filter
    times.forEach((time: Time) => {
      let match = false;

      // match keyword
      if (isKeywordDefined) {
        match = (time.description.toLowerCase().indexOf(key) >= 0
          || time.taskMailSubject.toLowerCase().indexOf(key) >= 0);
      }

      // match start date
      if (startDateDefined) {
        const date = moment(this.queryParams.start);
        if (!isKeywordDefined || match == true) {
          match = (date.isValid() && date <= moment(time.dateOfService));
        }
      }

      // match end date
      if (endDateDefined) {
        const date = moment(this.queryParams.end);
        if (!startDateDefined || match == true) {
          match = (date.isValid() && date >= moment(time.dateOfService));
        }
      }

      // push if matching filter
      if (match == true) {
        filter.push(time);
      }
    });

    return filter;
  }

  public onCustomerChanged(customer: Customer) {
    this.customer = customer;
    this.dropdownCustomerText = customer.company;

    this.projects = this.projectService.findByCustomer(customer.id);
  }

  public onProjectChanged(project: Project) {
    this.project = project;
    this.dropdownProjectText = project.nameShort + ': ' + project.name;

    this.onFilterButtonClicked();
  }

  public isFilterSelected(): boolean {
    return (this.queryParams.key != null
      || this.queryParams.project != null
      || this.queryParams.start != null
      || this.queryParams.end != null);
  }

  public onFilterButtonClicked(): void {
    const self = this;
    const params: any = {};

    // append project
    if (this.project != null) {
      params.project = this.project.id;
    }

    // append key
    if ($('#key').val() != '') {
      params.key = $('#key').val();
    }

    // append date range
    if ($('#start').val() != '') {
      params.start = $('#start').val();
    }
    if ($('#end').val() != '') {
      params.end = $('#end').val();
    }

    if (Object.keys(params).length > 0) {
      self.router.navigate(['times/search'], { queryParams: params })
          .then(() => {
            self.initialize();
          });
    } else {
      // filter not selected, reset tasks list
      this.times = new Observable<Time[]>();
    }
  }

  public isProjectSelectOptionSelected(project: Project): boolean {
    return (project.id == this.queryParams.project);
  }

  public getDate(timestamp: number): string {
    return moment(timestamp).format('DD.MM.YYYY');
  }
}
