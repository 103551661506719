import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
    selector: '[appDropdownLink]',
    templateUrl: './dropdown-link.component.html',
    styleUrls: ['./dropdown-link.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropdownLinkComponent implements OnChanges {

    @Input() title: string;
    @Input() style: 'default' | 'primary' | 'success' | 'error';
    @Input() fullWidth = false;

    constructor(private elementRef: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (typeof this.title == 'undefined') {
            this.title = 'Actions';
        }
        if (['default', 'primary', 'success', 'error'].indexOf(this.style) == -1) {
            console.log('TypeError: Invalid value (' + this.style
                + ') for property "style". Style value updated to "default".');
            this.style = 'default';
        }
        this.onChangesCloseList();
    }

    private onChangesCloseList() {
        const elements = (this.elementRef.nativeElement as HTMLElement).querySelectorAll('.open');
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('open');
        }
    }

    public toggleList(event: MouseEvent) {
        const element = (event.target as HTMLElement);
        const parent = element.parentElement;
        const target = parent.nextElementSibling;

        if (!parent.classList.contains('open')) {
            parent.classList.add('open');
            target.classList.add('open');
        } else {
            parent.classList.remove('open');
            target.classList.remove('open');
        }
    }
}
