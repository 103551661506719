import {Injectable} from '@angular/core';
import {Time} from '../model/model';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    public static mapDateStringToDate(date: string): Date {
        return moment(date).toDate();
    }

    public static mapTimestampToDateString(timestamp: number): string {
        return moment(timestamp).format('YYYY-MM-DD');
    }

    public static calculateDuration(timeStart: number,
                                    timeEnd: number,
                                    format?: 'hour' | 'min' | 'sec' | 'ms'): number {
        const duration = moment.duration(moment(timeEnd)
            .diff(moment(timeStart)))
        if (format == 'hour') {
            return duration.asHours();
        }
        if (format == 'min') {
            return duration.asMinutes();
        }
        if (format == 'sec') {
            return duration.asSeconds();
        }
        return duration.asMilliseconds()
    }

  public static dateStringToTimestamp(date: string): number {
    if (typeof date == 'undefined') {
      return 0;
    }
    return moment(date, 'YYYY-MM-DD').toDate().getTime();
  }

  public static toDateString(time: Time): string {
    const date = moment(time.dateOfService).locale('de');
    const today = moment().locale('de');

    // today
    if (date.isSame(today, 'day')) {
      return 'Today';
    }

    // yesterday
    if (date.isSame(today.subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }

    const format = (today.format('YYYY') == date.format('YYYY'))
      ? 'ddd, DD/MM'
      : 'DD/MM - YYYY';

    // future
    if (date.isAfter(today)) {
      return date.format(format) + ' - Future';
    }

    return date.format(format);
  }

  public isToday(dateString: string) {
    const date = moment(dateString).locale('de');
    const today = moment().locale('de');
    return date.isSame(today, 'day');
  }

  public isPast(dateString: string) {
    const date = moment(dateString).locale('de');
    const today = moment().locale('de');
    return date.isBefore(today);
  }

  public isFuture(dateString: string) {
    const date = moment(dateString).locale('de');
    const today = moment().locale('de');
    return date.isAfter(today);
  }
}
