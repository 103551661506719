import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Person, PersonService, API_URL_GCP} from 'commons';
import {Time} from '../../model/model';
import {TimeService} from '../../services/time.service';
import {DateService} from '../../services/date.service';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-person-stats',
  templateUrl: './person-stats.component.html',
  styleUrls: ['./person-stats.component.scss']
})
export class PersonStatsComponent implements OnInit {

  public person: Person;
  public times: Time[];
  public period = {
    month: 0,
    year: 0
  };

  constructor(private route: ActivatedRoute,
              private appService: AppService,
              private personService: PersonService,
              private timeService: TimeService) {
    const date = new Date();
    this.period.month = date.getMonth() + 1;
    this.period.year = date.getFullYear();
  }

  ngOnInit() {
    this.person = null;
    this.times = [];

    this.route.params.subscribe(params => {
      if (params.id) {
        this.getPerson(params.id);
        this.getTimes(params.id);
      }
    });
  }

  private getPerson(personId: string): void {
    this.personService.findById(personId).then(person => {
      this.person = person;
    });
  }

  private getTimes(personId: string): void {
    this.timeService.findByPersonAndPeriod(personId, this.period.year, this.period.month)
      .subscribe((times: Time[]) => {
        this.times = times;
      });
  }

  public getTotalWorkingHours(): string {
    let duration = 0;
    this.times.forEach(time => {
      duration += +time.duration;
    });
    return this.getMinutesInHours(duration);
  }

  public getMinutesInHours(minutes: number): string {
    return (minutes / 60).toFixed(2);
  }

  public toDateString(time: Time): string {
    return DateService.toDateString(time);
  }

  public export() {
    this.appService.downloadExcelFile(
      API_URL_GCP + '/sleektime/export/times'
      + '?year=' + this.period.year
      + '&month=' + this.period.month
      + '&personId=' + this.person.id
    );
  }
}
