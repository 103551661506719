import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  public page: number;
  public maxPage: number;
  public itemsPerPage: number;
  public numberOfItems: number;

  constructor() {
    this.page = 1;
    this.maxPage = 0;
    this.itemsPerPage = 10;
    this.numberOfItems = 0;
  }

  public init(numberOfItems: number, itemsPerPage: number): void {
    this.page = 1;
    this.maxPage = Math.ceil(numberOfItems / itemsPerPage);
    this.itemsPerPage = itemsPerPage;
    this.numberOfItems = numberOfItems;
  }

  public isEnabled(): boolean {
    return (this.maxPage >= 1);
  }

  public itemIsEnabled(index: number): boolean {
    if (this.isEnabled()) {
      return (this.getMinIndex() <= index && index <= this.getMaxIndex());
    }
    return false;
  }

  public hasPrevious(): boolean {
    return (this.page > 1);
  }

  public hasNext(): boolean {
    return (this.page < this.maxPage);
  }

  public onItemClicked(page: number): void {
    // lower
    if (page < 1) {
      page = 1;
    }

    // upper
    if (page > this.maxPage) {
      page = this.maxPage;
    }

    // set page
    this.page = page;
  }

  private getMinIndex(): number {
    return (this.itemsPerPage * (this.page - 1));
  }

  private getMaxIndex(): number {
    return ((this.getMinIndex() + this.itemsPerPage) - 1);
  }
}
