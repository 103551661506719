import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {
  Customer,
  CustomerService,
  Project,
  ProjectService
} from 'commons';
import {TimeService} from '../../services/time.service';
import {NoticeService} from '../../services/notice.service';
import {DateService} from '../../services/date.service';
import {Notice, Time} from '../../model/model';

@Component({
  selector: 'app-time-update',
  templateUrl: './time-update.component.html',
  styleUrls: ['./time-update.component.scss']
})
export class TimeUpdateComponent implements OnInit {

  public alert: {
    project: boolean,
    duration: boolean,
    description: boolean,
    customText: boolean | string
  } = {
    project: false,
    duration: false,
    description: false,
    customText: false
  };

  private url: string;

  public time: Time;
  public selectedNotice: Notice = null;

  public customers: Customer[];
  public projects: Project[];
  public notices: Observable<Notice[]>;

  public dropdownCustomerText = 'Please select';
  public dropdownProjectText = 'Please select';

  public customer: Customer;
  public project: Project;

  public updating: boolean;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private _cd: ChangeDetectorRef,
              private _customerService: CustomerService,
              private _projectService: ProjectService,
              private timeService: TimeService,
              private noticeService: NoticeService) {
  }

  ngOnInit() {
    this.url = this.router.url;
    let itemId = null;

    this.activatedRoute.params.subscribe((params: any) => {
      itemId = (typeof params.id != 'undefined') ? params.id : null;
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.rel == 'notice' && params.id) {
        this.getNoticeById(params.id);
      }
    });

    this.init(itemId).then(() => {
      this._cd.markForCheck();
    });
  }

  private async init(timeId) {
    await this._customerService.findAll().then(response => {
      this.customers = response;
    });

    await this._projectService.findAll().then(response => {
      this.projects = response;
    });

    if (timeId != null) {
      this.timeService.findById(timeId).subscribe((response: Time) => {
        this.time = response;

        this.project = this.getProjectById(response.projectId);
        this.onProjectChanged(this.project);

        this.customer = this.getCustomerById(this.project.customerId);
        this.onCustomerChanged(this.customer);

        this.getTaskNotices(response);
      });
    }
  }

  private getCustomerById(id: string): Customer {
    const list = this.customers.filter(customer => customer.id === id);
    return (list.length > 0) ? list[0] : null;
  }

  private getProjectById(id: string): Project {
    const list = this.projects.filter(project => project.id === id);
    return (list.length > 0) ? list[0] : null;
  }

  private getTaskNotices(time: Time) {
    this.notices = this.noticeService.findByTask(time);
  }

  private getNoticeById(id: string) {
    this.noticeService.findById(id)
        .toPromise()
        .then(notice => {
          this.selectedNotice = notice ? notice : null;
        });
  }

  private resetAlert() {
    this.alert.project = false;
    this.alert.duration = false;
    this.alert.description = false;
    this.alert.customText = false;
  }

  public onCustomerChanged(customer: Customer) {
    this.customer = customer;
    this.dropdownCustomerText = customer.company;

    this._projectService.findByCustomer(customer.id).then(response => {
      this.projects = response;
    });
  }

  public onProjectChanged(project: Project) {
    this.project = project;
    this.dropdownProjectText = project.nameShort + ': ' + project.name;
  }

  public onFormSubmit(): void {
    this.updating = true;
    this.resetAlert();

    if (this.customer == null || this.project == null) {
      this.alert.project = true;
      return;
    }

    const data: Time = {
      ...this.time,
      projectId: this.project.id,
      dateOfService: document.getElementsByName('dateOfService')[0]['value'],
      taskMailDate: DateService.dateStringToTimestamp(document.getElementsByName('taskMailDate')[0]['value']),
    };

    if (data.duration == 0) {
      this.alert.duration = true;
      return;
    }
    if (data.description == '') {
      this.alert.description = true;
      return;
    }

    this.timeService.update(this.time.id, data).subscribe((response) => {
      if (typeof response == 'object') {
        this.router.navigate(['times', 'view', response.id]).then();
      }
      this.updating = false;
    });
  }

  public onDeleteRequested(): void {
    this.timeService.delete(this.time.id).then(() => {
      this.router.navigate(['times']).then();
    });
  }

  public isDetailsView(): boolean {
    return (this.url.startsWith('/times/view'));
  }

  public isUpdateView(): boolean {
    return (this.url.startsWith('/times/update'));
  }

  public isDeleteView(): boolean {
    return (this.url.startsWith('/times/delete'));
  }

  public getDate(timestamp: number): string {
    return DateService.mapTimestampToDateString(timestamp);
  }

  public resetFormSubTask(): void {
    // this.formSubTask.reset();
  }

  public submitFormSubTask(): void {
    this.resetAlert();

    const formValues: any = {}; // this.formSubTask.value;
    if (formValues.text.length < 10) {
      this.alert.customText = 'A subtask comment cannot be empty.';
      return;
    }

    const notice: Notice = {
      id: undefined,
      taskId: this.time.id,
      createdAt: null,
      updatedAt: null,
      dateStart: null,
      dateEnd: null,
      duration: formValues.duration,
      text: formValues.text
    };

    const date = this.time.dateOfService;
    if (formValues.dateStart != '') {
      notice.dateStart = DateService.mapDateStringToDate(date + ' ' + formValues.dateStart);
    }
    if (formValues.dateEnd != '') {
      notice.dateEnd = DateService.mapDateStringToDate(date + ' ' + formValues.dateEnd);
    }
    if (notice.dateStart != null && notice.dateEnd != null) {
      notice.duration = DateService.calculateDuration(
          notice.dateStart.getTime(),
          notice.dateEnd.getTime(),
          'min'
      );
    }

    this.noticeService.create(notice)
        .subscribe(() => {
          this.resetFormSubTask();
          this.getTaskNotices(this.time);
        });
  }

  public updateNotice(): void {
    this.resetAlert();

    const formValues: any = {}; // this.formSubTask.value;
    if (formValues.text.length < 10) {
      this.alert.customText = 'A subtask comment cannot be empty.';
      return;
    }

    const notice: Notice = {
      id: this.selectedNotice.id,
      taskId: this.selectedNotice.taskId,
      createdAt: null,
      updatedAt: null,
      dateStart: null,
      dateEnd: null,
      duration: formValues.duration,
      text: formValues.text
    };

    const date = this.time.dateOfService;
    if (formValues.dateStart != '') {
      notice.dateStart = DateService.mapDateStringToDate(date + ' ' + formValues.dateStart);
    }
    if (formValues.dateEnd != '') {
      notice.dateEnd = DateService.mapDateStringToDate(date + ' ' + formValues.dateEnd);
    }
    if (notice.dateStart != null && notice.dateEnd != null) {
      notice.duration = DateService.calculateDuration(
          notice.dateStart.getTime(),
          notice.dateEnd.getTime(),
          'min'
      );
    }

    this.noticeService.update(notice.id, notice)
        .subscribe(() => {
          this.resetFormSubTask();

          this.router.navigate(['/times/view/' + this.time.id])
              .then(() => {
                this.getTaskNotices(this.time);
              });
        });
  }

  public onDeleteNotice(notice: Notice): void {
    this.noticeService.delete(notice.id)
        .subscribe(() => {
          this.getTaskNotices(this.time);
        });
  }
}
