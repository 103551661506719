import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL_GCP} from 'commons';

@Injectable({
    providedIn: 'root'
})
export class RestService {

    public static URL = API_URL_GCP + '/sleektime';

    constructor(private http: HttpClient) {
    }

    public getAuthorization(): HttpHeaders {
        let headers = new HttpHeaders();
        const authToken = (sessionStorage.getItem('b_token') != null)
            ? sessionStorage.getItem('b_token')
            : '';
        headers = headers.set('Authorization', authToken);
        return headers;
    }

    public get(url: string): Observable<any> {
        return this.http.get<any>(RestService.URL + url);
    }

    public getWithHeaders(url: string, headers: HttpHeaders): Observable<any> {
        return this.http.get<any>(RestService.URL + url, {headers: headers});
    }

    public post(url: string, body: any): Observable<any> {
        return this.http.post(RestService.URL + url, body, {headers: this.getAuthorization()});
    }

    public put(url: string, body: any): Observable<any> {
        return this.http.put(RestService.URL + url, body, {headers: this.getAuthorization()});
    }

    public delete(url: string): Observable<any> {
        return this.http.delete(RestService.URL + url, {headers: this.getAuthorization()});
    }
}
