import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {
  AuthenticationService,
  CommonsModule,
  CustomerService,
  PersonService,
  ProjectService
} from 'commons';

import {AppRoutingModule} from './app-routing.module';

import {RestService} from './services/rest.service';
import {AppService} from './services/app.service';
import {TimeService} from './services/time.service';
import {PaginationService} from './services/pagination.service';

import {AppComponent} from './app.component';
import {StatsComponent} from './component/stats/stats.component';
import {TimeComponent} from './component/time/time.component';
import {TimeAddComponent} from './component/time-add/time-add.component';
import {TimeUpdateComponent} from './component/time-update/time-update.component';
import {TimeSearchComponent} from './component/time-search/time-search.component';
import {PersonStatsComponent} from './component/person-stats/person-stats.component';

import {AlertComponent} from './shared/alert/alert.component';
import {DropdownLinkComponent} from './shared/dropdown-link/dropdown-link.component';
import {DateService} from './services/date.service';
import {PopupComponent} from './common/popup/popup.component';

@NgModule({
  declarations: [
    AppComponent,
    TimeComponent,
    TimeAddComponent,
    TimeUpdateComponent,
    StatsComponent,
    TimeSearchComponent,
    PersonStatsComponent,
    AlertComponent,
    DropdownLinkComponent,
    PopupComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonsModule
  ],
  providers: [
    RestService,
    AppService,
    AuthenticationService,
    PersonService,
    CustomerService,
    ProjectService,
    TimeService,
    PaginationService,
    DateService,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
