import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'pop-up',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent {

    @Input() public text: string;
    @Input() public icon: boolean;
    @Input() public closable: boolean;

    @Output() public close = new EventEmitter<boolean>();

    constructor() {
        if (!this.text) {
            this.text = 'Please set some text';
        }
    }

    public onClose() {
        this.close.emit(true);
    }
}
