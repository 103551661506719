import {Injectable} from '@angular/core';
import {Person} from 'commons';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _sessionUser: Person;

  public setSessionUser(person: Person) {
    this._sessionUser = person ? person : null;
  }

  public getUser() {
    return this._sessionUser;
  }

  public isLoggedIn() {
    return (this._sessionUser != null);
  }
}
