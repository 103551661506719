import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: '[appAlert]',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

    @Input('type') public alertType: string;
    @Input() public title: string;
    @Input() public message: string;
    @Input() public hasCloseButton = true;
    @Output() private onCloseEvent = new EventEmitter<boolean>();

    public onClose() {
        this.onCloseEvent.emit(false);
    }
}
