import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthenticationService, ChartLine} from 'commons';
import {TimeService} from '../../services/time.service';
import {Time} from '../../model/model';

import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  public chartFilter = 'month';
  private entities: Time[] = [];
  private mappedTimes: any = {};

  public chartLineData: ChartLine[] = [];

  constructor(private _cd: ChangeDetectorRef,
              private authenticationService: AuthenticationService,
              private timeService: TimeService) {
  }

  ngOnInit() {
    const self = this;

    this.authenticationService.getPerson()
      .then(person => {
          if (person != null && typeof person.id != 'undefined') {
            self.getTimes(person.id);
          }
        },
        error => {
          console.log('Authentication failed.', error);
        }
    );
  }

  private getTimes(personId: string) {
    const self = this;
    this.timeService.findByPerson(personId)
      .pipe(map(entities => {
        const map: Time[] = [];
        const todayFor12Months = moment()
          .subtract(12, 'months')
          .startOf('day')
          .toDate();

        entities.forEach(entity => {
          const date = moment(entity.dateOfService).toDate();
          if (date >= todayFor12Months) {
            map.push(entity);
          }
        });

        return map;
      }))
      .subscribe(entities => {
      self.entities = entities;

      self.buildMappedTimes();
      self.onChartFilterChanged(this.chartFilter);
    });
  }

  private buildMappedTimes(): void {
    const obj: any = {};

    this.entities.forEach(time => {
      const key = moment(time.dateOfService).format('YYYY-MM-DD');
      if (obj.hasOwnProperty(key)) {
        obj[key] += time.duration;
      } else {
        obj[key] = time.duration;
      }
    });

    this.mappedTimes = obj;
  }

  private getNumberOfMinutes(): number {
    let duration = 0;
    this.entities.forEach(entity => {
      duration += entity.duration;
    });
    return duration;
  }

  private renderChart(labels: string[], values: number[]) {
    const lineData: ChartLine[] = [];

    labels.forEach((label, index) => {
      lineData.push({
        label: label,
        value: values[index] | 0
      });
    });

    this.chartLineData = lineData;
    this._cd.markForCheck();
  }

  public getNumberOfDays(): number {
    const days: string[] = [];
    this.entities.forEach(entity => {
      const date = moment(entity.dateOfService).format('YYYY-MM-DD');
      if (days.indexOf(date) == -1) {
        days.push(date);
      }
    });
    return days.length;
  }

  public getWorkingTimeInHours(): string {
    let duration = 0;
    this.entities.forEach(entity => {
      duration += entity.duration;
    });
    return this.timeService.getTimeInKFormat(duration / 60);
  }

  public getWorkingTimeInMinutes(): string {
    return this.timeService.getTimeInKFormat(this.getNumberOfMinutes());
  }

  public getNumberOfEntities(): number {
    return this.entities.length;
  }

  public getAverageWorkingTime(): string {
    const entities = this.getNumberOfEntities();
    const duration = this.getNumberOfMinutes() / 60;

    // avoid division by zero
    if (entities == 0) {
      return '--';
    }

    return (duration / entities).toFixed(2);
  }

  public getAverageWorkingHoursPerDay(): string {
    const days = this.getNumberOfDays();
    const duration = this.getNumberOfMinutes();

    // avoid division by zero
    if (days == 0) {
      return '--';
    }

    return ((duration / 60) / days).toFixed(1);
  }

  public onChartFilterChanged(value: string) {
    let labels: string[] = [];
    let values: number[];

    this.chartFilter = value;

    // update chart properties
    if (['month', '7-days', '30-days'].indexOf(value) >= 0) {
      // chart series data by days
      let dates: Moment[] = [];

      switch (value) {
        case 'month':
          dates = StatsComponent.getChartDatesInMonth();
          break;

        case '7-days':
          dates = StatsComponent.getChartDatesInLast7Days();
          break;

        case '30-days':
          dates = StatsComponent.getChartDatesInLast30Days();
          break;
      }

      labels = this.getChartLabels(dates, value);
      values = this.getChartSeriesData(dates);
    } else {
      // chart series data by month
      let start: Moment = moment();
      let end: Moment = moment();

      // whole year
      if (value == 'year') {
        labels = ['JAN.', 'FEB.', 'MAR.', 'APR.', 'MAY', 'JUN.', 'JUL.', 'AUG.', 'SEP.', 'OCT.', 'NOV.', 'DEC.'];
        start = moment().startOf('year');
        end = start.clone().add(12, 'months');
      }

      // last 6 / 3 months
      if (value == '6-months' || value == '3-months') {
        const numberOfMonths = (value === '6-months') ? 6 : 3;
        const startMonth = moment().subtract(numberOfMonths, 'months');

        // set start
        start = startMonth.clone();
        end = moment().endOf('month');

        for (let month = 0; month < numberOfMonths; month++) {
          labels.push(startMonth.add(1, 'month')
            .format('MMM. YYYY')
            .toUpperCase());
        }
      }

      // update chart series data
      values = this.getChartSeriesDataByMonth(start, end);
    }

    this.renderChart(labels, values);
  }

  private static getChartDatesInMonth(): Moment[] {
    const start = moment().startOf('month')
      .subtract(1, 'day');
    const end = moment().endOf('month');
    return StatsComponent.getDates(start, end);
  }

  private static getChartDatesInLast7Days(): Moment[] {
    const start = moment().subtract(7, 'days');
    const end = moment();
    return StatsComponent.getDates(start, end);
  }

  private static getChartDatesInLast30Days(): Moment[] {
    const start = moment().subtract(31, 'days');
    const end = moment();
    return StatsComponent.getDates(start, end);
  }

  private static getDates(start: Moment, end: Moment): Moment[] {
    const dates: Moment[] = [];
    while (start.diff(end, 'days') < 0) {
      const date = start.add(1, 'days');
      dates.push(date.clone());
    }
    return dates;
  }

  private getChartLabels(dates: Moment[], selectedFilter: string): string[] {
    const labels: string[] = [];
    const format = (selectedFilter === '7-days') ? 'DD/MM' : 'DD.';

    dates.forEach(date => {
      labels.push(!date.isSame(moment(), 'day')
        ? date.format(format)
        : 'Today');
    });
    return labels;
  }

  private getChartSeriesData(dates: Moment[]): number[] {
    const seriesData: number[] = [];

    dates.forEach(date => {
      const key = date.format('YYYY-MM-DD');
      const match = (this.mappedTimes.hasOwnProperty(key)) ? this.mappedTimes[key] : 0;

      // set number of match
      seriesData.push(Math.round((match / 60) * 100) / 100);
    });
    return seriesData;
  }

  private getChartSeriesDataByMonth(start: Moment, end: Moment): number[] {
    const seriesData: number[] = [];
    const mapTimeByMonth: any = {};

    // always use start of month
    start = start.startOf('month');
    end = end.startOf('month');

    // iterates over times
    this.entities.forEach(time => {
      const key = moment(time.dateOfService).format('YYYY-MM');
      if (mapTimeByMonth.hasOwnProperty(key)) {
        mapTimeByMonth[key] += time.duration;
      } else {
        mapTimeByMonth[key] = time.duration;
      }
    });

    while (start.diff(end, 'months') < 0) {
      const key = start.format('YYYY-MM');
      const match = mapTimeByMonth.hasOwnProperty(key) ? mapTimeByMonth[key] : 0;
      seriesData.push(Math.round((match / 60) * 100) / 100);

      start.add(1, 'months');
    }

    return seriesData;
  }
}
