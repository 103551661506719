import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestService} from './rest.service';
import {Notice, Time} from '../model/model';

@Injectable({
    providedIn: 'root'
})
export class NoticeService {

    constructor(private rest: RestService) {
    }

    public findByTask(time: Time): Observable<Notice[]> {
        return this.rest.get('/time/notices?taskId=' + time.id);
    }

    public findById(id: string): Observable<Notice> {
        return this.rest.get('/time/notices/' + id);
    }

    public create(notice: Notice): Observable<Notice> {
        return this.rest.post('/time/notices', notice);
    }

    public update(id: string, notice: Notice): Observable<Notice> {
        return this.rest.put('/time/notices/' + id, notice);
    }

    public delete(id: string): Observable<boolean> {
        return this.rest.delete('/time/notices/' + id);
    }
}
