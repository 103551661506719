import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  AuthenticationService,
  Customer,
  CustomerService,
  Project,
  ProjectService
} from 'commons';
import {TimeService} from '../../services/time.service';
import {Time} from '../../model/model';
import {DateService} from '../../services/date.service';

@Component({
  selector: 'app-time-add',
  templateUrl: './time-add.component.html',
  styleUrls: []
})
export class TimeAddComponent implements OnInit {

  public alert = {
    project: false,
    duration: false,
    description: false
  };

  public time: Time;
  public customers: Promise<Customer[]>;
  public projects: Promise<Project[]>;

  public dropdownCustomerText = 'Please select';
  public dropdownProjectText = 'Please select';

  private customer: Customer = null;
  private project: Project = null;

  public updating: boolean;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private customerService: CustomerService,
              private projectService: ProjectService,
              private timeService: TimeService) {
  }

  ngOnInit() {
    this.customers = this.customerService.findAll();
    this.time = {
      id: undefined,
      dateCreate: new Date().toDateString(),
      dateOfService: new Date().toDateString(),
      personId: undefined,
      projectId: undefined,
      duration: 0,
      description: '',
      taskMailSubject: '--',
      taskMailDate: Date.now(),
      taskMailSender: '--',
      storyID: '--'
    };

    this.authenticationService.isLoggedIn()
      .subscribe(loggedIn => {
        if (loggedIn) {
          this.time.personId = this.authenticationService.person.id;
        }
      });
  }

  public onCustomerChanged(customer: Customer) {
    this.customer = customer;
    this.dropdownCustomerText = customer.company;

    this.projects = this.projectService.findByCustomer(customer.id);
  }

  public onProjectChanged(project: Project) {
    this.project = project;
    this.dropdownProjectText = project.nameShort + ': ' + project.name;
  }

  public onFormSubmit() {
    this.updating = true;

    // reset alerts
    this.alert.project = false;
    this.alert.duration = false;
    this.alert.description = false;

    if (this.customer == null || this.project == null) {
      this.alert.project = true;
      return;
    }

    const dateOfService = document.getElementsByName('dateOfService')[0]['value'];
    const taskMailDate = DateService.dateStringToTimestamp(document.getElementsByName('taskMailDate')[0]['value']);

    const data: Time = {
      ...this.time,
      projectId: this.project.id,
      dateOfService: dateOfService,
      taskMailDate: taskMailDate
    };

    if (data.duration == 0) {
      this.alert.duration = true;
      return;
    }
    if (data.description == '') {
      this.alert.description = true;
      return;
    }

    this.timeService.insert(data).subscribe((response) => {
      this.router.navigate(['times', 'view', response.id]).then();
      this.updating = false;
    });
  }
}
