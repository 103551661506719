import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestService} from './rest.service';
import {Time} from '../model/model';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor(private rest: RestService) {
  }

  public findAll(): Observable<Time[]> {
    return this.rest.get('/times');
  }

  public findByPersonAndPeriod(personId: string, year: number, month: number): Observable<Time[]> {
    return this.rest.get('/times/person/' + personId + '/period/' + year + '/' + month);
  }

  public findByPeriod(year: number, month: number): Observable<Time[]> {
    return this.rest.get('/times/period/' + year + '/' + month);
  }

  public findByPerson(personId: string): Observable<Time[]> {
    return this.rest.get('/times/person/' + personId);
  }

  public findByProject(projectId: string): Observable<Time[]> {
    return this.rest.get('/times/project/' + projectId);
  }

  public findById(id: string): Observable<Time> {
    return this.rest.get('/times/' + id);
  }

  public insert(time: Time): Observable<Time> {
    return this.rest.post('/times', time);
  }

  public update(id: string, time: Time): Observable<Time> {
    return this.rest.put('/times/' + id, time);
  }

  public delete(id: string): Promise<boolean> {
    return this.rest.delete('/times/' + id)
      .toPromise();
  }

  public getTimeInKFormat(num): string {
    return (Math.abs(num) > 999)
        ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k'
        : (Math.sign(num) * Math.abs(num)).toString()
  }
}
