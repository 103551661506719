import {Injectable} from '@angular/core';
import {FileService, RestService} from 'commons';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private restService: RestService,
              private fileService: FileService) {
  }

  public static isNumeric(num: any): boolean {
    if (num == null) {
      return false;
    }
    return (typeof num != 'undefined' && !isNaN(num));
  }

  public downloadExcelFile(url: string) {
    this.restService.download(url)
      .then(base64String => {
        this.fileService.downloadExcel(base64String);
      });
  }
}
